<template>
  <TemplateFoo.define v-slot="{ msg }">
    <div>Hello {{ msg.toUpperCase() }}</div>
  </TemplateFoo.define>

  <TemplateFoo.reuse msg="World" />
  <TemplateFoo.reuse msg="Reusable" />
</template>

<script setup lang="ts">
import { createReusableTemplate } from '@vueuse/core';

const TemplateFoo = createReusableTemplate<{ msg: string }>();
</script>
